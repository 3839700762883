body {
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body,
p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: content-box !important;
}

code {
  font-family: calibri, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  line-height: 1.5;
}

div,
p {
  font-family: Arial, sans-serif;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  padding: 0 1rem;
}

ol {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.15;
}

h1,
.h1 {
  font-weight: bold;
  font-size: 1.875rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

h3,
.h3 {
  font-size: 1.438rem;
}

h4,
.h4 {
  font-size: 1.313rem;
}

h5,
.h5 {
  font-size: 1.125rem;
  font-weight: normal;
}

h6,
.h6 {
  font-size: 1rem;
}

b {
  font-weight: bold;
}

button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: Arial, sans-serif;
}

input {
  font-family: Arial, sans-serif;
}

a {
  color: #0050aa;
}
a:hover {
  color: #003674;
  text-decoration: underline;
}

svg {
  vertical-align: 0 !important;
}
