.status {
  &-page {
    padding: 20px;
  }
  &-grid {
    display: grid;
    grid-template-columns: 5fr 1fr;
    max-width: 800px;
    grid-gap: 10px;
  }

  &-light {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #999;

    &--green {
      background: #9c5;
    }
    &--red {
      background: #f60;
    }
  }
}